import OrdersComponent from '~/shop/front/components/userProfile/Orders'

class Orders {
	status = ''
	processing = false
	form = false
	orders = []
	component = OrdersComponent

	constructor (app) {
		this.app = app
	}

	get translations () {
		return this.app.translator.get('forms.clientChangePassword')
	}

	async fetchOrders () {
		const service = await this.app.getService('rext')
		this.orders = await service.getOrders()
	}

	async init () {
		await this.app.translator.prefetch('errors')
		await this.app.translator.prefetch('forms.clientChangePassword')

		await this.fetchOrders()
	}
}
export {
	Orders
}
